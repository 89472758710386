import Api from '@/services/Index';

const getUserGuide = async (config) => {
    config.responseType= 'arraybuffer';
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('user-guide', config);
}

export default {
    getUserGuide
}
