<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('dashboard')" :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('dashboard')"
                    :isFilter="false"
                ></HeaderMobile>
            </template>
            <div>
                <div
                    class="d-flex flex-column flex-lg-row justify-content-between"
                >
                    <div class="flex-grow-1 mr-0 mr-md-5">
                        <h5>{{ $t("fast_transactions") }}</h5>
                        <div class="border rounded p-4">
                            <b-row>
                                <b-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    lg="3"
                                    v-for="(menu, menuKey) in menus"
                                    :key="'menu' + menuKey"
                                >
                                    <div
                                        class="bg-light rounded p-4 mb-4 cursor-pointer"
                                        @click="goToUrl(menu.url)"
                                    >
                                        {{ menu.name.toUpper() }}
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <div style="min-width: 300px" v-if="videoManuals && videoManuals.items.length">
                        <h5>{{ $t("Video Manuals") }}</h5>
                        <div class="border rounded p-4 pt-5 pb-5 bg-light">
                            <div
                                class="d-flex align-items-center font-size-14 mb-2 btn-link cursor-pointer"
                                v-for="item in videoManuals.items" @click="playVideo(item)"
                            >
                                <i class="ri-video-line ri-2x mr-2"></i>
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CommonModal id="videoModal" size="xl">
                <template v-slot:CommonModalTitle>
                    {{ video?.name }}
                </template>
                <template v-slot:CommonModalContent>
                    <div v-if="videoManuals && videoManuals.items">
                        <div v-for="item in videoManuals.items" v-bind:key="item.id">
                            <video width="100%" height="100%" controls autoplay v-if="item?.show">
                                <source :src="item?.url" :type="item.file_type">
                            </video>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import CommonModal from "@/components/elements/CommonModal";

// Services
import DashboardService from "@/services/DashboardService";
import StudentProgramService from "@/services/StudentProgramService";
import Base from "@/plugins/Base";



//Other
import qs from "qs";
import ParameterService from "@/services/ParameterService";
import ParameterItemService from "@/services/ParameterItemService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t("dashboard"),
        };
    },
    data() {
        return {
            userStore: {},
            videoManuals:null,
            video:null
        };
    },
    computed: {
        user() {
            return this.$store.getters["auth/getUser"];
        },
        menus() {
            let menus = this.$store.getters["auth/getMenus"];

            let data = [];
            if (menus && menus.length > 0) {
                menus.forEach((menu) => {
                    if (menu.items && menu.items.length) {
                        menu.items.forEach((item) => {
                            if (item.permission_id!=711){
                                data.push(item);
                            }

                        });
                    }
                });
            }

            return data;
        },
        isAdmin() {
            return this.$store.getters["auth/isAdmin"];
        },
        isStudent() {
            return this.$store.getters["auth/isStudent"];
        },
    },
    methods: {
        goToUrl(url) {
            this.$router.push(url);
        },
        getUserGuide() {
            let config = {
                params: { language: this.$i18n.locale },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };

            DashboardService.getUserGuide(config)
                .then((response) => {
                    this._downloadFile(
                        response,
                        "BAU-" +
                            this.$t("file_name_student_information_system") +
                            "-" +
                            this.$t("file_name_user_guide") +
                            ".pdf"
                    );
                })
                .catch((e) => {
                    this.$toast.error(this.$t("api." + e.data.message));
                });
        },

        checkEnrollmentForm() {
            this.$store.dispatch('auth/getUser').then(() => {
                let user = Base.LocalStorage.get('user');
                if(user && user.student && user.student.forms && user.student.forms.show_enrollment_agreement_form){
                    this.$router.push('/enrollment/agreement-form');
                }
            });

        },
        getVideoManuals(){
            ParameterService.getItems('video_manuals', {params:{sort:'rank'}}).then(response=>{
                this.videoManuals=response.data.data
            });
        },
        playVideo(item){
            if(this.video && this.video.id==item.id){
                this.$bvModal.show('videoModal');
                return;
            }
            else if(this.video){
                this.video.show=false;
            }
            let data = {
                id: item.id,
                uuid: item.file.uuid
            }
            ParameterItemService.createFileToken(data).then(response=>{
                item.url=process.env.VUE_APP_ENDPOINT_STUDENT + '/parameter/items/file-by-token/'+response.data.data;
                item.show=true;
                this.video=item;
                this.$bvModal.show('videoModal');
            });
        }
    },
   created() {
        this.checkEnrollmentForm();
        this.$store.dispatch("dashboard/getAppImages");
        this.getVideoManuals();
    },
};
</script>
